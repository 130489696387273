import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { PteState } from '@/store/pte/types';
import { RootState } from '../types';

const state: PteState = {
  pteTabs: [],
  tabSelected: {},
};

const actions: ActionTree<PteState, RootState> = {
  togglePteTabs({ commit }, payload) {
    commit('setPteTabs', payload);
  },

  toggleTabSelected({ commit }, payload) {
    commit('setTabSelected', payload);
  },
};

const mutations: MutationTree<PteState> = {
  setPteTabs: (state, payload) => {
    state.pteTabs = payload;
  },

  setTabSelected: (state, payload) => {
    state.tabSelected = payload;
  },
};

const getters: GetterTree<PteState, RootState> = {
  getPteTabs(state) {
    return state.pteTabs;
  },

  getTabSelected(state) {
    return state.tabSelected;
  },
};

const index: Module<PteState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
