import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { LayoutState } from '@/store/layout/types';
import { RootState } from '../types';

const state: LayoutState = {
  drawer: false,
  theme: false,
  invitationClosedUserID: [],
  shareFeedbackClosedID: [],
  discountIsShowed: false,
  completedReportCount: 0,
};

const actions: ActionTree<LayoutState, RootState> = {
  toggleDrawer({ commit }) {
    commit('TOGGLE_DRAWER');
  },

  toggleInvitationId({ commit }, payload) {
    commit('InvitationUserIdSetter', payload);
  },

  toggleShareFeedbackId({ commit }, payload) {
    commit('shareFeedbackUserIdSetter', payload);
  },

  toggleDiscountIsShowed({ commit }, payload) {
    commit('setDiscountIsShowed', payload);
  },

  toggleCompletedReportCount({ commit }, payload) {
    commit('setCompletedReportCount', payload);
  },
};

const mutations: MutationTree<LayoutState> = {
  TOGGLE_DRAWER: (state) => {
    state.drawer = !state.drawer;
  },

  InvitationUserIdSetter: (state, payload) => {
    if (!state.invitationClosedUserID.includes(payload)) state.invitationClosedUserID.push(payload);
  },

  shareFeedbackUserIdSetter: (state, payload) => {
    if (!state.shareFeedbackClosedID.includes(payload)) state.shareFeedbackClosedID.push(payload);
  },

  setDiscountIsShowed: (state) => {
    state.discountIsShowed = true;
  },

  setCompletedReportCount: (state, payload) => {
    state.completedReportCount = payload;
  },
};

const getters: GetterTree<LayoutState, RootState> = {
  drawerState(state) {
    return state.drawer;
  },

  getDiscountIsShowed(state) {
    return state.discountIsShowed;
  },

  getCompletedReportCount(state) {
    return state.completedReportCount;
  },
};

const index: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
