import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { provideApolloClient } from '@vue/apollo-composable';

const GQL_URI = '/api/graphql/query';
const WSS_URL = import.meta.env.PROD ? window.location.hostname : 'app.smalltalk2.me';

const httpLink = createHttpLink({
  uri: GQL_URI,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${WSS_URL}${GQL_URI}`,
  }),
);

export function createGraphqlClient(authToken: () => Promise<string | null>) {
  const authLink = setContext((operation, { headers }) =>
    authToken().then((token) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    })),
  );
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
  const cache = new InMemoryCache();

  const apolloClient = new ApolloClient({
    link: authLink.concat(link),
    cache,
    connectToDevTools: true,
  });
  provideApolloClient(apolloClient);

  return apolloClient;
}
